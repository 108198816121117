import * as React from "react"


// markup
const NotFoundPage = () => {
  return (
    <div></div>
  )
}

export default NotFoundPage
